import React from 'react';
import style from './Customers.module.css';
import { logos } from './customers';

export const Customers = () => {
  return (
    <div className={style.customers}>
      <div className={style.container}>
        <div className={style.customersList}>
          {logos.map((e) => (
            <div key={e.id} className={style.logoWrapper}>
              <img src={e.logoSrc} alt={e.alt} className={style.logo} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
