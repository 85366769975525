export const reviews = [
  {
    id: 1,
    author: 'Joanna Wachowiak-Jurasz',
    position: 'CEO - GUESS POLSKA',
    content: '…Fleetinsider to narzędzie, które nie tylko ułatwia zarządzanie kosztami paliwa, ale także pozwala na szczegółowe porównanie cen oferowanych przez różne karty paliwowe. Co ważne, przy takim porównaniu zawsze widzimy różnicę wynoszącą co najmniej 1 eurocent na litrze, co może wydawać się niewielką kwotą, ale przy regularnym tankowaniu przekłada się na realne oszczędności. Dla jednego pojazdu oznacza to minimum 25 euro oszczędności miesięcznie, warto zaznaczyć że różnice czasem są aż do 10-20 eurocentów na litrze, więc kalkulator pomoże nam zobaczyć ile tak naprawdę da się zaoszczędzić. Szczególnie w sytuacji kiedy paliwo jest jednym z niewielu kosztów który można szybko ograniczyć bez ponoszenia większego ryzyka…'
  },
  {
    id: 2,
    author: 'Iwona Henzel',
    position: 'CFO - DARTRANS & HENZEL',
    content: '…Fleetinsider stał się dla nas nieocenionym narzędziem. Dzięki tej platformie możemy szybko i łatwo porównać ceny paliwa z różnych kart, które posiadamy, wszystko w jednym miejscu. Wcześniej musieliśmy ręcznie sprawdzać i porównywać oferty, co było czasochłonne i często prowadziło do nieoptymalnych wyborów. Teraz mamy pełen przegląd cen, co pozwala nam podejmować lepsze decyzje i efektywniej zarządzać kosztami paliwa…'
  },
  {
    id: 3,
    author: 'Mikalai Krautsevich',
    position: 'CEO - EASY WAY',
    content: '…Fleetinsider to wszechstronne narzędzie, które nie tylko pomaga w zarządzaniu kosztami paliwa, ale również znacząco usprawnia kontrolę wydatków na płatne drogi. W naszej firmie transportowej, gdzie koszty związane z opłatami drogowymi w różnych krajach stanowią istotny element budżetu, możliwość monitorowania tych wydatków w jednym miejscu jest ogromnym udogodnieniem. Platforma umożliwia szczegółową analizę opłat drogowych z podziałem na kraje, pojazdy oraz różne sposoby płatności, co pozwala nam szybko zidentyfikować, w których regionach generujemy najwyższe koszty…'
  }
];