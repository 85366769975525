import React, { useState } from 'react';
import LineMidLi from '../../assets/pictures/LineMidLi.svg';
import { cards } from './cards';
import { useTranslation } from 'react-i18next';
import style from './BlockThird.module.css';

export const BlockThird = () => {
  const { t } = useTranslation();
  const [hoverId, setHoverId] = useState(1);
  const handleChangeHoverId = (id) => {
    setHoverId(id);
  };
  return (
    <div id="Benefits" className={style.thirdBlock}>
      <div className={style.container}>
        <div className={style.titleContainer}>
          <div>
            <img src={LineMidLi} alt="li" />
          </div>
          <div className={style.title}>{t('ThirdBlock.Benefits')}</div>
        </div>
        <div className={style.contentWrapper}>
          {cards.map((e) => {
            const isActive = e.id === hoverId;
            return (
              <div
                key={e.id}
                onMouseEnter={() => handleChangeHoverId(e.id)}
                className={isActive ? style.activeCard : style.card}
              >
                {isActive ? (
                  <div className={style.activeCardLogo}>
                    <img src={e.activeImg} alt=" " />
                  </div>
                ) : (
                  <div className={style.cardLogo}>
                    <img src={e.Img} alt=" " />
                  </div>
                )}
                <div className={isActive ? style.activeCardSubTitle : style.cardSubTitle}>
                  {t(`ThirdBlock.${e.subTitle}`)}
                </div>
                <div className={style.cardTitle}>{t(`ThirdBlock.${e.title}`)}</div>
                <div className={style.responsiveCardLogo}>
                  <img src={e.activeImg} alt=" " />
                </div>
                <div className={style.responsivecardTitle}>{t(`ThirdBlock.${e.title}`)}</div>
                <div className={style.responsiveCardSubTitle}>{t(`ThirdBlock.${e.subTitle}`)}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
