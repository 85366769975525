import React, { useEffect } from 'react';
import './i18n';
import LandingPage from './pages/LandingPage/LandingPage';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';


const App = () => {

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-CVT0ZDT0N0'); 
    ReactGA.send('pageview');

    ReactPixel.init('2525563674311876');
    ReactPixel.pageView();

    // Add LinkedIn Insight Tag
    const loadLinkedInInsightTag = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        _linkedin_partner_id = "7697017";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);
        })(window.lintrk);
      `;
      document.body.appendChild(script);
    };

    loadLinkedInInsightTag();

    // Cleanup function
    return () => {
      // Remove LinkedIn Insight Tag script if needed
      const scripts = document.getElementsByTagName('script');
      for (let script of scripts) {
        if (script.src.includes('snap.licdn.com/li.lms-analytics/insight.min.js')) {
          script.remove();
          break;
        }
      }
    };
  }, []);

  return (
  <>

   <LandingPage />
  </>
);
};

export default App;

// import TagManager from 'react-gtm-module'
// import React from 'react';
// import './i18n';
// import LandingPage from './pages/LandingPage/LandingPage'

// const tagManagerArgs = {
//   gtmId: 'G-CVT0ZDT0N0'
// }
// TagManager.initialize(tagManagerArgs)

// const App = () => (<LandingPage />);

// export default App;