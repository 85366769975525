import React, { useState } from 'react';
import style from './Reviews.module.css';
import { reviews } from './reviews';
import { useTranslation } from 'react-i18next';
import LineMidEndLi from '../../assets/pictures/LineMidEndLi.svg';

export const Reviews = () => {
  const [hoverId, setHoverId] = useState(1);
  const { t } = useTranslation();

  const handleChangeHoverId = (id) => {
    setHoverId(id);
  };

  return (
    <div className={style.reviewsBlock}>
      <div className={style.container}>
      <div className={style.titleContainer}>
          <div>
            <img src={LineMidEndLi} alt="li" />
          </div>
          <div className={style.title}>{t('Reviews.Reviews')}</div>
        </div>

        <div className={style.contentWrapper}>
          {reviews.map((e) => {
            const isActive = e.id === hoverId;
            return (
              <div
                key={e.id}
                onMouseEnter={() => handleChangeHoverId(e.id)}
                className={isActive ? style.activeCard : style.card}
              >
                <div className={isActive ? style.activeCardSubTitle : style.cardSubTitle}>
                  {e.content}
                </div>
                <div className={isActive ? style.activeCardTitle : style.cardTitle}>
                  <div className={style.authorBlock}>
                    <p className={isActive ? style.activeAuthor : style.author}>{e.author}</p>
                    <p className={isActive ? style.activePosition : style.position}>{e.position}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
