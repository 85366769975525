import dartrans from '../../assets/pictures/dartrans-logo_white.png';
import easyway from '../../assets/pictures/easyway_white.png';
import jasek from '../../assets/pictures/jasek-white.png';
import guessPolska from '../../assets/pictures/guessPolska-white.png';
import traffic from '../../assets/pictures/traffic_white.png';

export const logos = [
  { id: 1, logoSrc: dartrans, alt: 'dartrans' },
  { id: 2, logoSrc: easyway, alt: 'easyway' },
  { id: 3, logoSrc: jasek, alt: 'jasek' },
  { id: 4, logoSrc: guessPolska, alt: 'guessPolska' },
  { id: 5, logoSrc: traffic, alt: 'traffic' },
];
