import React, { useMemo } from 'react';
import logo from '../../assets/pictures/logo.svg';
import Select from 'react-select';
import Arrow from '../../assets/pictures/arrow.svg';
import style from './HeaderStyle.module.css';

import './SelectStyles.css';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hoocks/useWindowSize';

const Header = ({ handleScroll, changeLanguage, languages }) => {
  const { t, i18n } = useTranslation();
  const currentLng = i18n.language;

  const size = useWindowSize();

  const is550 = size.width < 550;

  const options = useMemo(() => {
    return languages.map((lng) => ({
      value: lng.locale,
      label: lng.image_flag,
    }));
  }, [languages]);

  const handleChange = (lng) => {
    changeLanguage(lng.value);
  };

  const getBaseUrl = () => {
    if (process.env.NODE_ENV === 'development') {
      return 'https://dev-app.fleetinsider.eu';
    }
    return 'https://app.fleetinsider.eu';
  };

  const redirectLogIn = () => {
    window.location.href = `${getBaseUrl()}/login`;
  };

  const redirectSignUp = () => {
    window.location.href = `${getBaseUrl()}/register`;
  };

  return (
    <div className={style.headerWrapper}>
      <div className={`${style.header} ${style.container}`}>
        <div className={style.logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={style.buttons}>
          <button onClick={() => handleScroll('ContactUs')} className={style.buttonContact}>
            {t('Header.Contact Us')}
          </button>
          <Select
            className={'react-select-container '}
            classNamePrefix="react-select"
            components={{
              DropdownIndicator: () => <img className={style.selectImg} src={Arrow} alt=" " />,
              IndicatorSeparator: () => null,
            }}
            defaultValue={options.find((e) => e.value === currentLng)}
            isSearchable={false}
            options={options}
            onChange={handleChange}
          />
          <div className={style.login}>
            {is550 ? (
              ''
            ) : (
              <>
                <button className={style.button} onClick={redirectLogIn}>
                  {t('Header.Log In')}
                </button>
                <div className={style.border}></div>
              </>
            )}

            {is550 ? (
              <button className={style.buttonSignup} onClick={redirectSignUp}>
                {t('Header.Sign UP Button')}
              </button>
            ) : (
              <button className={style.buttonSignup} onClick={redirectSignUp}>
                {t('Header.Sign UP Button')}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
